<template>
  <section class="container">
    <v-row class="d-flex justify-center align-center">
      <v-col
        cols="12"
        md="4"
        class="d-flex align-center justify-center flex-column mb-md-5"
        :align-self="'center'"
        :align-md-self="'end'"
      >
        <router-link
          :to="{ name: 'sobre_bento_tradicoes_resistencias_resistencia' }"
          class="d-flex flex-column align-center"
          ><v-img
            max-width="50%"
            src="../../../assets/sobre_bento_tradicao_resistencia_02.png"
          ></v-img>
          <p class="orange--text mb-0" style="text-align: center;">
            Tradição e resistência
          </p></router-link
        >
      </v-col>
    </v-row>

    <v-row class="d-flex justify-md-space-between align-center">
      <v-col
        cols="12"
        md="4"
        class="d-flex flex-column mt-md-5"
        :align-self="'end'"
        :align-sm-self="'center'"
      >
        <router-link
          :to="{ name: 'sobre_bento_refencias_simbolicas' }"
          class="d-flex flex-column align-center"
        >
          <v-img
            max-width="60%"
            src="../../../assets/sobre_bento_tradicao_resistencia_01.png"
          ></v-img>
          <p class="orange--text mb-5" style="text-align: center;">
            As referências simbólicas
          </p>
        </router-link>
      </v-col>

      <v-col
        cols="12"
        md="4"
        class="d-flex align-center justify-end flex-column mt-md-5"
        :align-self="'center'"
        :align-md-self="'end'"
      >
        <router-link
          :to="{ name: 'sobre_loucos_pelo_bento' }"
          class="d-flex flex-column align-center"
          ><v-img
            max-width="60%"
            src="../../../assets/sobre_cultura_bento_01.png"
          ></v-img>
          <p class="orange--text mb-0" style="text-align: center; width: 80%">
            Os "Loucos pelo Bento"
          </p></router-link
        >
      </v-col>
    </v-row>

    <v-row class="d-flex justify-center align-center">
      <v-col
        cols="12"
        md="4"
        class="d-flex align-center justify-start flex-column mt-md-5"
        :align-self="'center'"
        :align-md-self="'end'"
      >
        <router-link
          :to="{ name: 'sobre_bento_time_futebol' }"
          class="d-flex flex-column align-center"
          ><v-img
            max-width="100%"
            src="../../../assets/sobre_bento_tradicao_resistencia_03.png"
          ></v-img>
          <p class="orange--text mb-0" style="text-align: center;">
            O Time de Futebol
          </p></router-link
        >
      </v-col>
    </v-row>
  </section>
</template>

<script>
export default {};
</script>

<style scoped>
a {
  text-decoration: none;
}

p {
  font-size: 0.8em;
}
</style>
